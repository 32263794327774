#onesignal-slidedown-container.onesignal-slidedown-container #onesignal-slidedown-dialog {
  background: #24243b !important;
  border: 1px solid #323352;
  border-radius: 16px;
  margin-top: 16px;
}

#onesignal-slidedown-container .slidedown-body {
  display: flex;
  flex-direction: row-reverse;
}

#onesignal-slidedown-container.onesignal-slidedown-container
  #onesignal-slidedown-dialog
  .slidedown-body-message {
  color: #f9fafa !important;
  font-family: 'Rubik_500Medium';
  font-size: 16px;
  line-height: 20px;
  padding: 0 16px 0 0;
}

#onesignal-slidedown-container .slidedown-body-message::after {
  color: #a2a2b1;
  content: 'Pentru a face acest lucru, vom utiliza informații privind comportamentul și acțiunile pe care le realizezi din contul tău de utilizator din OneUp pentru a-ți crea un profil de consumator.\A\ANotificările pot include alerte, sunete și pictograme și prin acestea te vom anunța despre cele mai noi produse, premii sau campanii, oferte, concursuri și evenimente relevante pentru tine.';
  display: block;
  font-family: 'Roboto_400Regular';
  font-size: 14px;
  line-height: 16px;
  margin-top: 16px;
  white-space: pre-line;
}

#onesignal-slidedown-container .slidedown-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

#onesignal-slidedown-container.onesignal-slidedown-container #onesignal-slidedown-dialog .clearfix {
  display: none;
}

#onesignal-slidedown-container.onesignal-slidedown-container
  #onesignal-slidedown-dialog
  .slidedown-button {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 48%;
}

#onesignal-slidedown-container.onesignal-slidedown-container
  #onesignal-slidedown-dialog
  .slidedown-button.primary,
#onesignal-slidedown-container.onesignal-slidedown-container
  #onesignal-slidedown-dialog
  .slidedown-button.primary
  + .secondary.slidedown-button {
  background-color: transparent !important;
  border: 1px solid #867fe1;
  color: #867fe1 !important;
  font-family: 'Rubik_500Medium';
  font-size: 16px;
  line-height: 20px;
  margin-right: 0;
}

#onesignal-slidedown-container.onesignal-slidedown-container
  #onesignal-slidedown-dialog
  .slidedown-button.primary:active,
#onesignal-slidedown-container.onesignal-slidedown-container
  #onesignal-slidedown-dialog
  .slidedown-button.primary
  + .secondary.slidedown-button:active {
  background-color: #29215f !important;
}
